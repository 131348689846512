.userdata-report-container {
  min-height: 100vh;
  background-color: white;
}

.action-buttons {
  margin: 15px;
}

.ant-col.action-col {
  margin: 15px;
}
